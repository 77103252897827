import React, { createContext, useContext, useState } from 'react';

const QuoteContext = createContext();

const QuoteProvider = ({ children }) => {
  const [state, setState] = useState({ error: null, quote: null });

  const setQuote = quote =>
    setState(state => ({
      ...state,
      quote,
    }));

  const setError = hasError =>
    setState(state => ({
      ...state,
      error: hasError,
    }));

  return (
    <QuoteContext.Provider
      value={{ error: state.error, quote: state.quote, setError, setQuote }}
    >
      {children}
    </QuoteContext.Provider>
  );
};

const useQuote = () => useContext(QuoteContext);

export { QuoteProvider, useQuote };
