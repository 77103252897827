import styled from 'styled-components';

const Header = () => {
  return (
    <CroisetteHeader>
      <CroisetteLogo
        src='https://d2l0gvltru79ov.cloudfront.net/partners/croisette/IA_ikon.svg'
        alt='Croisette Real Estate Partner'
      />
    </CroisetteHeader>
  );
};

export default Header;

const CroisetteHeader = styled.header`
  margin: 2.8125rem 5.625rem;
`;

const CroisetteLogo = styled.img`
  width: 12.9375rem;
  height: auto;
`;
