import { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import Header from '../../components/Header';

const PaymentView = () => {
  const match = useRouteMatch('/pay/trustly/:quoteId');
  const [error, setError] = useState(false);

  fetch(
    `${process.env.REACT_APP_AWS_ENDPOINT_URL}purchase/trustly/${match.params.quoteId}`,
    {
      method: 'put',
      redirect: 'follow',
    }
  )
    .then(response => response.json())
    .then(data => window.location.replace(data.redirectUrl))
    .catch(err => setError(true));

  return error ? (
    <ContentContainer>
      <Header />
      <main>
        <h1>Något gick fel</h1>
        <p>
          Det gick inte att genomföra betalningen. Vänligen kontakta vår
          kundeservice på telefon 08-520 276 58.
        </p>
      </main>
    </ContentContainer>
  ) : (
    <ContentContainer>
      <Header />
      <main>
        <h1>Vänligen vänta..</h1>
      </main>
    </ContentContainer>
  );
};

export default PaymentView;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 56.25rem;
  max-width: 56.25rem;
  margin: 1.875rem auto;

  main {
    padding: 0.625rem;

    @media (min-width: 48rem) {
      padding: 1.875rem;
    }
  }

  h1 {
    font-family: DM Serif Text;
    color: #3c3c3c;
    font-size: 1.6rem;
    line-height: 1.3;
    font-weight: 400;
    text-align: center;

    @media (min-width: 35rem) {
      font-size: 3.125rem;
    }
  }

  h2 {
    font-family: DM Serif Text;
    color: #3c3c3c;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.3;
  }
`;
