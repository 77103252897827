import styled from 'styled-components';

const ErrorMessage = () => {
  return (
    <p>
      Tyvärr kan vi inte ge dig något pris. Kontakta vår kundtjänst på telefon{' '}
      <Phone>08 - 520 276 58</Phone> eller infocroisette@eirforsakring.se så
      hjälper dem dig vidare.
    </p>
  );
};

export default ErrorMessage;

const Phone = styled.span`
  white-space: nowrap;
`;
