import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_AWS_ENDPOINT_URL,
  headers: {
    'content-type': 'application/json',
    Authorization: 'Basic Y3JvaXNldHRlOlF4bTIxTG5wbXlhSTBGcVQ4SWVM',
  },
});

export default axiosInstance;
