import PropTypes from 'prop-types';
import styled from 'styled-components';

const PageContainer = ({ children }) => {
  return <Container>{children}</Container>;
};

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageContainer;

const Container = styled.div`
  font-family: Roboto;
  font-weight: 300;
  margin: 1rem 0;

  @media print {
    margin: 0 8mm;
  }
`;
