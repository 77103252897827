import styled from 'styled-components';

const Page2 = () => {
  return (
    <>
      <ThreeColumnGrid>
        <div>
          <Strong>I övrigt gäller</Strong>
        </div>
        <SpanDiv>
          <P>
            Prisuppgift grundar sig bland annat på information från både interna
            och externa datakällor, exempelvis från adressregister,
            kreditregister och fastighetsregister.
          </P>
        </SpanDiv>
        <div>
          <Strong>Personuppgifter</Strong>
        </div>
        <SpanDiv>
          <P>
            Vi värnar om dina personuppgifter och behandlar dem i enlighet med
            dataskydds-förordningen (GDPR) och övriga tillämpliga regler.
          </P>
          <P>
            Försäkringsgivaren behandlar de personuppgifter om dig som krävs för
            att fullgöra försäkringsavtalet. För information om
            försäkringsgivarens behandling av personuppgifter, läs mer här:
            www.eirforsakring.se
          </P>
          <P>
            För information om Croisette’s behandling av personuppgifter, läs
            mer här: www.croisette.se/boendeforsakring
          </P>
        </SpanDiv>
        <div>
          <Strong>Försäkringsvillkor</Strong>
        </div>
        <SpanDiv>
          <P>Croisette Hem 1.1 20210906</P>
          <P>Försäkringsvillkoren finns på www.croisette.se/boendeforsakring</P>
        </SpanDiv>
        <div>
          <Strong>Försäkringsförmedlare</Strong>
        </div>
        <SpanDiv>
          <P>
            Croisette Insurance Advisory AB, org nr 559270-4505, Propellergan 2,
            211 15 Malmö
          </P>
          <P>
            Croisette Insurance Advisory AB har tillstånd från och står under
            tillsyn av Finansinspektionen (FI).
          </P>
        </SpanDiv>
        <div>
          <Strong>Försäkringsgivare</Strong>
        </div>
        <SpanDiv>
          <P>
            Eir Försäkring AB, org nr 559166-0617, Kungsgatan 37, 111 56
            Stockholm
          </P>
          <P>
            Eir Försäkring AB har tillstånd från och står under tillsyn av
            Finansinspektionen (FI)
          </P>
        </SpanDiv>
      </ThreeColumnGrid>
      <ContactInformationContainer>
        <ContactInformation>
          <p>Kundtjänst & skadeservice</p>
          <p>kundtjänst: infocroisette@eirforsakring.se</p>
          <p>skador: skador@eirforsakring.se</p>
        </ContactInformation>
      </ContactInformationContainer>
    </>
  );
};

export default Page2;

const ThreeColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 5fr 5fr;
  row-gap: 1.5rem;
  margin-bottom: 1.5rem;

  @media print {
    margin: 0 -8mm;
    font-size: 0.8rem;
  }
`;

const SpanDiv = styled.div`
  grid-column: 2 / span 2;
`;

const Strong = styled.span`
  font-weight: 500;
`;

const P = styled.p`
  margin-bottom: 0.5rem;
`;

const ContactInformationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 6rem;

  @media print {
    margin-top: 2rem;
  }
`;
const ContactInformation = styled.div`
  background-image: linear-gradient(180deg, #f0e3e2, #eedbdb, #d4c0c0);
  border-radius: 1.5rem;
  font-family: DM Serif Text;
  font-size: 1.8rem;
  font-weight: 300;
  color: #000;
  padding: 3rem 2rem;
  line-height: 1.1;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  @media print {
    font-size: 1.5rem;
    padding: 1.5rem;
  }
`;
