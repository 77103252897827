import styled from 'styled-components';

import { Icon } from '@eir/ui';

const ImportantInformationContainer = () => {
  return (
    <Container>
      <a
        href='https://f.hubspotusercontent10.net/hubfs/9295317/F%C3%B6rk%C3%B6psinformatiom%20Croisette%20Hem%201.0.pdf?__hstc=247822936.485b7136991dc3b284af4afa559f268f.1630139212784.1633701733359.1634279750999.7&__hssc=247822936.6.1634279750999&__hsfp=534120011&hsCtaTracking=a478f499-5426-4907-9912-853d85fe7220%7Cd38bf4a9-fb85-4cc7-a270-6a9b2d45b779'
        without
        rel='noopener noreferrer'
        target='_blank'
      >
        <Item>
          <Icon iconName='faFilePdf' /> Förköpsinformation
        </Item>
      </a>
      <a
        href='https://f.hubspotusercontent10.net/hubfs/9295317/IPID%20Croisette%20Hem%201.0.pdf?__hstc=247822936.485b7136991dc3b284af4afa559f268f.1630139212784.1633701733359.1634279750999.7&__hssc=247822936.4.1634279750999&__hsfp=534120011&hsCtaTracking=1738580d-446f-4a81-be6b-c40b64fc5685%7C66f3b642-3f7a-4adb-a6c4-e508cc29b3a6'
        without
        rel='noopener noreferrer'
        target='_blank'
      >
        <Item>
          <Icon iconName='faFilePdf' /> Produktfaktablad
        </Item>
      </a>
      <a
        href='https://f.hubspotusercontent10.net/hubfs/9295317/Croisette%20Hem%201.1%2020210906.pdf?__hstc=247822936.485b7136991dc3b284af4afa559f268f.1630139212784.1633701733359.1634279750999.7&__hssc=247822936.3.1634279750999&__hsfp=534120011&hsCtaTracking=a3c5242e-9aa8-4913-a999-ea5effe18361%7Cc02981ab-c793-475c-aeae-ed2f639334bf'
        without
        rel='noopener noreferrer'
        target='_blank'
      >
        <Item>
          <Icon iconName='faFilePdf' /> Fullständiga villkor
        </Item>
      </a>
    </Container>
  );
};

export default ImportantInformationContainer;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-top: 5rem;

  @media (min-width: 40rem) {
    flex-direction: row;
    justify-content: center;
  }
`;

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border: 0.0625rem solid #e14e9b;
  background-color: #eddcdc;
  border-radius: 2.5rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  width: 20rem;
  font-family: DM Serif Text;
  font-weight: 400;
  color: #323232;
  padding: 0.3rem 0;
  text-decoration: underline;
  cursor: pointer;

  @media (min-width: 40rem) {
    width: 12rem;
  }
`;
