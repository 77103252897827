import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const SocialSecuritySeInput = ({ value = '', name = '', onChange }) => {
  const [personalNumber, setPersonalNumber] = useState(value);
  const [customSelection, setCustomSelection] = useState(null);

  const inputRef = useRef();

  useEffect(() => {
    if (customSelection || customSelection === 0) {
      inputRef.current.setSelectionRange(customSelection, customSelection);
      setCustomSelection(null);
    }
  }, [personalNumber]);

  const handleChange = e => {
    const { selectionStart, value } = e.target;

    let newValue = value.replace('-', '');

    setCustomSelection(selectionStart);

    // Handle that personal number can be max 11 characther
    // and only containg number, - is added below
    if (
      value.length > 11 ||
      (personalNumber.length < value.length &&
        isNaN(value.charAt(value.length - 1)))
    ) {
      return;
    }

    // If cursor i placed just after the dash the number before is removed
    if (personalNumber.indexOf('-') > 0 && value.indexOf('-') < 0) {
      newValue =
        newValue.substring(0, 5) + newValue.substring(6, newValue.length);

      setCustomSelection(5);
    }

    // Add dash after the sixth character
    if (newValue.length >= 6) {
      newValue = `${newValue.substring(0, 6)}-${newValue.substring(
        6,
        newValue.length
      )}`;
    }

    // Set the cursor to end if adding to end of the, +1 to handle dash
    if (
      personalNumber.length < value.length &&
      value.length === selectionStart
    ) {
      setCustomSelection(value.length + 1);
    }

    setPersonalNumber(newValue);
    onChange(e);
  };

  return (
    <input
      ref={inputRef}
      value={personalNumber}
      name={name}
      placeholder='ÅÅMMDD-nnnn'
      onChange={handleChange}
    />
  );
};

SocialSecuritySeInput.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
};

export default SocialSecuritySeInput;
