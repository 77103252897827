import styled from 'styled-components';

const Page1 = ({ data }) => {
  const {
    answers: {
      address,
      area,
      firstName,
      lastName,
      optCondoAddOn,
      optExtra,
      optTravel,
      postalCode,
      residents,
      securitySolution,
    },
    policyPremium: { totalPremiumExcludingTax },
    start,
  } = data;

  return (
    <>
      <ThreeColumnGrid>
        <div>
          <Strong>Försäkringstagare</Strong>
        </div>
        <SpanDiv>
          {firstName} {lastName}
        </SpanDiv>
        <div>
          <Strong>Försäkringstid</Strong>
        </div>
        <SpanDiv>
          Försäkringen gäller från och med {start} med förnyelse månadsvis för
          ytterligare en månad om inte uppsägning har skett. Försäkringen gäller
          utan bindningstid.
        </SpanDiv>
        <div>
          <Strong>Boendeform</Strong>
        </div>
        <SpanDiv>
          {optCondoAddOn === 'true' ? 'Bostadsrätt' : 'Hyresrätt'}
        </SpanDiv>
        <div>
          <Strong>Försäkringsställe</Strong>
        </div>
        <SpanDiv>
          {address}, {postalCode}
        </SpanDiv>
        <div>
          <Strong>Total boyta</Strong>
        </div>
        <SpanDiv>{area} kvm</SpanDiv>
        <div>
          <Strong>Antal försäkrade</Strong>
        </div>
        <SpanDiv>{residents}</SpanDiv>
        <div>
          <Strong>Säkerhetsdörr</Strong>
        </div>
        <SpanDiv>{securitySolution}</SpanDiv>
        <div>
          <Strong>Total årspremie</Strong>
        </div>
        <SpanDiv>
          {totalPremiumExcludingTax.toLocaleString('sv-SE', {
            maximumFractionDigits: 0,
          })}{' '}
          kr
        </SpanDiv>
        <div>
          <Strong>Månadspremie</Strong>
        </div>
        <SpanDiv>Se betalningsplan på sida 3</SpanDiv>
        <div>
          <Strong>Betalsätt</Strong>
        </div>
        <SpanDiv>Autogiro den 27:e varje månad</SpanDiv>
      </ThreeColumnGrid>
      <ThreeColumnGrid>
        <div></div>
        <div>
          <Strong>Grundsjälvrisk</Strong>
        </div>
        <div>1 500 kr</div>
      </ThreeColumnGrid>
      <ThreeColumnGrid>
        <div></div>
        <div>
          <Strong>I försäkringen ingår</Strong>
        </div>
        <div>
          <Strong>Försäkringsbelopp</Strong>
        </div>
        <div></div>
        <div>Egendom (brand, stöld, vatten, mm)</div>
        <div>1 500 000 kr</div>
        <div></div>
        <div>Egendom utanför bostaden</div>
        <div>50 000 kr</div>
        <div></div>
        <div>Ansvarsskydd</div>
        <div>5 000 000 kr</div>
        <div></div>
        <div>Rättsskydd</div>
        <div>400 000 kr</div>
        <div></div>
        <div>Överfallsskydd</div>
        <div>1 000 000 kr</div>
        <div></div>
        <div>Krisskydd</div>
        <div>se villkor</div>
        <div></div>
        <div>Kränkande behandling i skolan</div>
        <div>se villkor</div>
        <div></div>
        <div>Ombudshjälp vid våld i nära relation</div>
        <div>se villkor</div>
        <div></div>
        <div>Reseförsäkring</div>
        <div>se villkor</div>
        {optExtra === 'true' && (
          <>
            <div></div>
            <div>Extraskydd Hem (tillval)</div>
            <div>se villkor</div>
          </>
        )}
        {optTravel === 'true' && (
          <>
            <div></div>
            <div>Extraskydd Resa (tillval)</div>
            <div>se villkor</div>
          </>
        )}
        {optCondoAddOn === 'true' && (
          <>
            <div></div>
            <div>Bostadsrättstillägg (tillval)</div>
            <div>Fullvärde</div>
          </>
        )}
      </ThreeColumnGrid>

      <ThreeColumnGrid>
        <div></div>
        <SpanDiv>
          <Strong>
            Det är de fullständiga villkoren som gäller vid skada.
          </Strong>
        </SpanDiv>
        <div></div>
        <SpanDiv>
          Titta i villkoret för att se eventuella avvikande självrisker vid
          exempelvis elektronikförsäkring, överfall m.m.
        </SpanDiv>
      </ThreeColumnGrid>
    </>
  );
};

export default Page1;

const ThreeColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 5fr 5fr;
  row-gap: 0.5rem;
  margin-bottom: 1.5rem;

  @media print {
    margin: 0 -8mm;
    font-size: 0.8rem;
    margin-bottom: 1.5rem;
  }
`;

const SpanDiv = styled.div`
  grid-column: 2 / span 2;
`;

const Strong = styled.span`
  font-weight: 500;
`;
