import styled from 'styled-components';

import background from '../../../images/header_bg.png';
import logo from '../../../images/logo_white.png';

const Header = ({ policyNumber }) => {
  return (
    <LetterHead>
      <TopSection>
        <LogoContainer>
          <img src={logo} alt='Croisette' />
        </LogoContainer>
        <TextContainer>
          <h1>Försäkringsbrev</h1>
          <h2>
            Boendeförsäkring för
            <br /> Hyres- och Bostadsrätt
          </h2>
        </TextContainer>
      </TopSection>
      <BottomSection>
        <GreyContainer>Croisette Insurance Advisory AB</GreyContainer>
        <GreyContainer>
          Försärkringsnummer <span>{policyNumber}</span>
        </GreyContainer>
      </BottomSection>
    </LetterHead>
  );
};

export default Header;

const LetterHead = styled.header`
  margin: -2rem;
  margin-bottom: 1rem;
  width: calc(100% + 4rem);

  @media print {
    margin: 3rem -2rem;
  }
`;

const TopSection = styled.div`
  position: relative;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 100%;
  height: 10rem;

  @media print {
    height: 35mm;
  }
`;

const BottomSection = styled.div`
  display: flex;
  margin: 2rem;

  @media print {
    margin: 5mm;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 4rem;
  bottom: 1rem;

  img {
    width: 12rem;
    height: auto;
  }

  @media print {
    left: 10mm;
    bottom: 10mm;
  }
`;

const TextContainer = styled.div`
  position: absolute;
  left: 33rem;
  bottom: 1rem;

  h1 {
    font-family: DM Serif Text;
    font-size: 1.8rem;
    font-weight: 300;
    color: #fff;
    line-height: 1.1;
  }

  h2 {
    font-family: DM Serif Text;
    font-size: 1.2rem;
    font-weight: 300;
    color: #fff;
    line-height: 1.1;
  }

  @media print {
    left: 110mm;
    bottom: 10mm;
  }
`;

const GreyContainer = styled.div`
  background-image: linear-gradient(180deg, #f1f1f1, #ecebea, #d0d0d0);
  width: 23rem;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  &:last-child {
    margin-left: auto;
  }

  @media print {
    padding: 3mm;
    font-size: 0.8rem;
    width: 80mm;
  }
`;
