import { useRouteMatch } from 'react-router-dom';
import { Page, PageWrapper } from '@eir/letter';
import { getOsaPolicyService, useApiRequest } from '@eir/services';

import Footer from './components/Footer';
import Header from './components/Header';
import Page1 from './components/Page1';
import Page2 from './components/Page2';
import Page3 from './components/Page3';
import PageContainer from './components/PageContainer';

const PolicyLetterView = () => {
  const match = useRouteMatch('/p/:policyId');

  const { data } = useApiRequest(
    getOsaPolicyService('CroisetteAPI', match.params.policyId)
  );

  return (
    data && (
      <PageWrapper>
        <Page
          pageHeader={<Header policyNumber={data.policyNumber} />}
          content={
            <PageContainer>
              <Page1 data={data} />
            </PageContainer>
          }
          pageFooter={<Footer />}
        />
        <Page
          pageHeader={<Header policyNumber={data.policyNumber} />}
          content={
            <PageContainer>
              <Page2 />
            </PageContainer>
          }
          pageFooter={<Footer />}
        />
        <Page
          pageHeader={<Header policyNumber={data.policyNumber} />}
          content={
            <PageContainer>
              <Page3 paymentSchedule={data.paymentSchedule.schedule} />
            </PageContainer>
          }
          pageFooter={<Footer />}
        />
      </PageWrapper>
    )
  );
};

export default PolicyLetterView;
