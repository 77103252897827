import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import { configureAmplify } from '@eir/aws-authorization';

import AttachView from './views/AttachView/AttachView';
import OnlineSalesView from './views/OnlineSales/OnlineSalesView';
import PaymentView from './views/Payment/PaymentView';
import PolicyLetterView from './views/PolicyLetter/PolicyLetterView';
import PurchaseFailedView from './views/PurchaseFailed/PurchaseFailedView';
import PurchaseSuccessView from './views/PurchaseSuccess/PurchaseSuccessView';

import GlobalStyle from './styles/globalStyle';
import './styles/fonts.css';

import { QuoteProvider } from './context/quoteContext';

configureAmplify(
  {
    endpointName: 'CroisetteAPI',
    endpointUrl: process.env.REACT_APP_AWS_ENDPOINT_URL,
  },
  true
);

function App() {
  return (
    <QuoteProvider>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route exact path='/' component={OnlineSalesView} />
          <Route exact path='/tecknad' component={PurchaseSuccessView} />
          <Route exact path='/ooops' component={PurchaseFailedView} />
          <Route exact path='/p/:quoteId' component={PolicyLetterView} />
          <Route exact path='/pay/trustly/:quoteId' component={PaymentView} />
          <Route
            exact
            path='/reattach/trustly/:ledgerId'
            component={AttachView}
          />
          <Redirect from='/attach/:ledgerId' to='/reattach/trustly/:ledgerId' />
        </Switch>
      </Router>
    </QuoteProvider>
  );
}

export default App;
