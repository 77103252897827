import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
* {
  outline: none;
  box-sizing: border-box;
  margin: 0;
}

html {
  font-family: Roboto;
  font-size: 16px;
}

body {
  background-color: #eddcdc;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
  -moz-appearance: textfield;
}


`;

export default GlobalStyle;
