import styled from 'styled-components';

import logo from '../../../images/Croisette_logo_Pink.png';

const Footer = () => {
  return (
    <PageFooter>
      <img src={logo} alt='Croisette' />
    </PageFooter>
  );
};

export default Footer;

const PageFooter = styled.footer`
  //   width: 100%;
  display: flex;
  justify-content: flex-end;

  img {
    width: 8rem;
    height: auto;
  }
`;
