import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import DatePicker, { registerLocale } from 'react-datepicker';
import sv from 'date-fns/locale/sv';

import { range } from '@eir/utils';
import { Icon } from '@eir/ui';

import 'react-datepicker/dist/react-datepicker.css';
import '../../../styles/datepicker-fix.css';

import { useForm } from '../../../hooks/useForm';
import { createQuoteService } from '../../../api/services/quoteServices';
import { useQuote } from '../../../context/quoteContext';
import { formatDate } from '../../../utils';
import SocialSecuritySeInput from './SocialSecuritySeInput';
import ToggleSwitch from './ToggleSwitch';

registerLocale('sv', sv);

const useQueryParam = () => {
  return new URLSearchParams(useLocation().search);
};

const calculateAge = birthDate => {
  const diffMs = Date.now() - birthDate.getTime();
  const age = new Date(diffMs);

  return Math.abs(age.getUTCFullYear() - 1970);
};

const SalesForm = () => {
  const { setError, setQuote } = useQuote();
  const [loading, setLoading] = useState(false);
  const [showInfo, setShowInfo] = useState({
    cover: false,
    optExtra: false,
    optTravel: false,
  });

  const partnerId = useQueryParam().get('partner') || '';

  const { handleSubmit, handleChange, data, errors } = useForm({
    initialValues: {
      address: '',
      coverLevel: 'Base',
      email: '',
      firstName: '',
      sumInsured: '1500000',
      area: '',
      excess: '1500',
      residents: '',
      securitySolution: 'Nej',
      lastName: '',
      optCondoAddOn: 'false',
      optExtra: 'false',
      optTravel: 'false',
      paymentMethod: 'Monthly',
      customerAge: '',
      postalCode: '',
      product: 'croisette-contents',
      productVersion: 'latest',
      reference: partnerId,
      socialSecurity: '',
      startDate: new Date(),
      telephoneNumber: '',
    },
    validations: {
      socialSecurity: {
        required: {
          value: true,
          message: 'Fyll i ditt personnummer',
        },
      },
      postalCode: {
        required: {
          value: true,
          message: 'Fyll i postnummer',
        },
        pattern: {
          value: '^([0-9]{5})$',
          message: 'Fyll i postnummer',
        },
      },
      // PERSON_CustomerAge: {
      //   required: {
      //     value: true,
      //     message: 'Ange din ålder',
      //   },
      //   custom: {
      //     isValid: value => Number(value) >= 18,
      //     message: 'Du måste vara över 18 år',
      //   },
      // },
      area: {
        required: {
          value: true,
          message: 'Ange boyta',
        },
        custom: {
          isValid: value => Number(value) >= 15 && Number(value) <= 200,
          message: 'Boytan måste vara mellan 15 och 200 kvadratmeter',
        },
      },
      residents: {
        required: {
          value: true,
          message: 'Ange antal person i hushållet',
        },
      },
      coverLevel: {
        required: {
          value: true,
          message: 'Välj omfattning',
        },
      },
      sumInsured: {
        required: {
          value: true,
          message: 'Välj försäkrat belopp',
        },
      },
      excess: {
        required: {
          value: true,
          message: 'Välj självrisk',
        },
      },
      address: {
        required: {
          value: true,
          message: 'Ange adress',
        },
      },
      firstName: {
        required: {
          value: true,
          message: 'Ange ditt förnamn',
        },
      },
      lastName: {
        required: {
          value: true,
          message: 'Ange ditt efternamn',
        },
      },
      email: {
        required: {
          value: true,
          message: 'Ange en e-postadress',
        },
        pattern: {
          value: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$',
          message: 'Ange en e-postadress',
        },
      },
      telephoneNumber: {
        required: {
          value: true,
          message: 'Ange telefonnummer',
        },
        pattern: {
          value: '(07)[0-9]{8}$',
          message: 'Ange telefonnummer i formatet 07nnnnnnnn',
        },
      },
    },
    onSubmit: async () => {
      setLoading(true);
      try {
        const birthYear = () => {
          const year = Number(data.socialSecurity.substr(0, 2));
          const d = new Date();

          return year < d.getYear() - 100 ? 2000 + year : 1900 + year;
        };

        const customerAge = calculateAge(
          new Date(
            birthYear(),
            Number(data.socialSecurity.substr(2, 2)) - 1,
            Number(data.socialSecurity.substr(4, 2))
          )
        );

        const body = { ...data };
        body['startDate'] = formatDate(body['startDate']);
        body['socialSecurity'] = body['socialSecurity'].replace('-', '');
        body['customerAge'] = customerAge.toString();

        const response = await createQuoteService(body);
        setQuote(response.data);
        setError(null);
      } catch (err) {
        setQuote(null);
        setError(err);
        console.log(err.response.data.status, err.response.data.message);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleDateChange = date => {
    return date;
  };

  const [extraChecked, setExtraChecked] = useState(false);

  const handleExtraChange = checked => {
    setExtraChecked(extraChecked => ({
      ...extraChecked,
      optExtra: checked,
    }));

    return checked.toString();
  };

  const handleExtraTravelChange = checked => {
    setExtraChecked(extraChecked => ({
      ...extraChecked,
      optTravel: checked,
    }));

    return checked.toString();
  };

  const handleShowInfoClick = info =>
    setShowInfo(showInfo => ({
      ...showInfo,
      [info]: !showInfo[info],
    }));

  const handleDateChangeRaw = e => {
    e.preventDefault();
  };

  const handlePostalCodeChange = e => {
    const { value } = e.target;

    return value.replace(/\D/g, '');
  };

  return (
    <>
      <h1>För ditt pris, fyll i nedan:</h1>
      <FormContainer>
        <ItemContainer>
          <label>Jag/vi bor i</label>
          <select
            value={data.optCondoAddOn}
            onChange={handleChange('optCondoAddOn') || 'false'}
          >
            <option value='false'>Hyresrätt</option>
            <option value='true'>Bostadsrätt</option>
          </select>
        </ItemContainer>
        <ItemContainer>
          <label>Personnummer</label>
          <SocialSecuritySeInput
            value={data.socialSecurity || ''}
            onChange={handleChange('socialSecurity')}
          />
          <ErrorText>{errors?.socialSecurity}</ErrorText>
        </ItemContainer>
        <ItemContainer>
          <label>Förnamn</label>
          <input
            value={data.firstName || ''}
            onChange={handleChange('firstName')}
          />
          <ErrorText>{errors?.firstName}</ErrorText>
        </ItemContainer>
        <ItemContainer>
          <label>Efternamn</label>
          <input
            value={data.lastName || ''}
            onChange={handleChange('lastName')}
          />
          <ErrorText>{errors?.lastName}</ErrorText>
        </ItemContainer>
        <ItemContainerXs2>
          <label>Gatuadress</label>
          <input
            value={data.address || ''}
            onChange={handleChange('address')}
            required
          />
          <ErrorText>{errors?.address}</ErrorText>
        </ItemContainerXs2>
        <ItemContainer>
          <label>Postnummer</label>
          <input
            type='text'
            value={data.postalCode || ''}
            onChange={handleChange('postalCode', handlePostalCodeChange)}
            required
            maxLength='5'
            pattern='^[0-9]+$'
          />
          {/* <NumberInput
            value={data.postalCode || ''}
            onChange={handleChange('postalCode')}
            required
            maxLength='5'
          /> */}
          <ErrorText>{errors?.postalCode}</ErrorText>
        </ItemContainer>
        <ItemContainer>
          <label>Boyta</label>
          <select
            value={data.area || ''}
            required
            onChange={handleChange('area')}
          >
            <option value=''>-- Välj din boyta --</option>
            {range(15, 200).map(area => (
              <option key={`area_${area}`} value={area}>
                {area}
              </option>
            ))}
          </select>
          <ErrorText>{errors?.area}</ErrorText>
        </ItemContainer>
        <ItemContainer>
          <label>Antal personer i hushållet</label>
          <select
            value={data.residents || ''}
            required
            onChange={handleChange('residents')}
          >
            <option value=''>-- Välj antal personer --</option>
            {range(1, 8).map(residents => (
              <option key={`residents_${residents}`} value={residents}>
                {residents}
              </option>
            ))}
          </select>
          <ErrorText>{errors?.residents}</ErrorText>
        </ItemContainer>
        <ItemContainer>
          <label>Har du säkerhetsdörr?</label>
          <select
            value={data.securitySolution}
            required
            onChange={handleChange('securitySolution')}
          >
            <option value='Ja'>Ja</option>
            <option value='Nej'>Nej</option>
          </select>
        </ItemContainer>
        <ItemContainer>
          <label>Försäkringsbelopp för egendom</label>
          <select
            value={data.sumInsured}
            required
            onChange={handleChange('sumInsured')}
          >
            <option value='300000'>300 000 kr</option>
            <option value='1000000'>1 000 000 kr</option>
            <option value='1500000'>1 500 000 kr</option>
            <option value='2000000'>2 000 000 kr</option>
          </select>
          <ErrorText>{errors?.sumInsured}</ErrorText>
        </ItemContainer>
        <ItemContainer>
          <label>Självrisk</label>
          <input value='1 500 kr' disabled />
        </ItemContainer>
        <ItemContainerXs2>
          <label>Omfattning</label>
          <InfoContainer>
            <Cover>
              <span>Grundskydd</span>
              <Circle
                isUnfolded={showInfo.cover}
                onClick={() => handleShowInfoClick('cover')}
              >
                <Icon iconName='faChevronDown' />
              </Circle>
            </Cover>
          </InfoContainer>
        </ItemContainerXs2>
        <InfoContainerExpandable show={showInfo.cover}>
          <h3>Vad ingår i Grundskyddet?</h3>
          <p>Försäkringen omfattar följande försäkringsskydd:</p>
          <ul>
            <li>Stöld och skadegörelse</li>
            <li>Brand</li>
            <li>Läckage</li>
            <li>Väderfenomen</li>
            <li>Jordrörelser</li>
            <li>Hushållsmaskiner, installationer, glas och sanitet</li>
            <li>Trafikrelaterade skador</li>
            <li>Skada orsakad av vilda djur</li>
            <li>Ansvar</li>
            <li>Rättskydd</li>
            <li>Överfallsskydd</li>
            <li>Sjuk- och olycksfall på resa</li>
            <li>Krisskydd</li>
            <li>Identitetsstöld</li>
            <li>Ombudshjälp vid våld i nära relation</li>
          </ul>
        </InfoContainerExpandable>
        <InfoContainerWithBorder>
          <div style={{ display: 'flex' }}>
            <h3 style={{ flex: '1' }}>Extraskydd hem</h3>
            <Circle
              isUnfolded={showInfo.optExtra}
              onClick={() => handleShowInfoClick('optExtra')}
            >
              <Icon iconName='faChevronDown' />
            </Circle>
          </div>
          <ItemContainer>
            <ToggleSwitch
              id='opt-extra'
              checked={extraChecked.optExtra}
              onChange={handleChange('optExtra', handleExtraChange)}
            />
          </ItemContainer>
        </InfoContainerWithBorder>
        <InfoContainerExpandable show={showInfo.optExtra}>
          <h3>Vad ingår i Extraskydd Hem?</h3>
          <p>Tilläggsförsäkringen Extraskydd Hem omfattar:</p>
          <ul>
            <li>Allrisk lösöre, högsta ersättning 100 000 kr</li>
            <li>
              Utökat egendomsskydd, högsta ersättning för stöldbegärlig egendom
              utanför bostad höjs till 100 000 kr och stöldbegärlig egendom i
              bostad höjs till 300 000 kr.
            </li>
            <li>Maxnivån för cykel höjs till 50 000 kr</li>
            <li>Elektronikförsäkring</li>
          </ul>
        </InfoContainerExpandable>
        <InfoContainerWithBorder>
          <div style={{ display: 'flex' }}>
            <h3 style={{ flex: '1' }}>Extraskydd resa</h3>
            <Circle
              isUnfolded={showInfo.optTravel}
              onClick={() => handleShowInfoClick('optTravel')}
            >
              <Icon iconName='faChevronDown' />
            </Circle>
          </div>
          <ItemContainer>
            <ToggleSwitch
              id='opt-travel'
              checked={extraChecked.optTravel}
              onChange={handleChange('optTravel', handleExtraTravelChange)}
            />
          </ItemContainer>
        </InfoContainerWithBorder>
        <InfoContainerExpandable show={showInfo.optTravel}>
          <h3>Vad ingår i Extraskydd Resa?</h3>
          <p>Tilläggsförsäkringen Extraskydd Resa omfattar:</p>
          <ul>
            <li>Avbeställningsskydd</li>
            <li>Anslutning vid missad hem- och utresa</li>
            <li>Personförsening</li>
            <li>Bagageförsening</li>
            <li>Ersättning vid förlorad semestertid</li>
          </ul>
        </InfoContainerExpandable>
        <ItemContainerXs2>
          <label>Startdatum</label>
          <DatePicker
            locale='sv'
            dateFormat='yyyy-MM-dd'
            minDate={new Date()}
            selected={data.startDate || null}
            onChange={handleChange('startDate', handleDateChange)}
            onChangeRaw={handleDateChangeRaw}
          />
          <ErrorText>{errors?.startDate}</ErrorText>
        </ItemContainerXs2>
        <ItemContainerXs2>
          <label>E-post</label>
          <input
            value={data.email || ''}
            required
            onChange={handleChange('email')}
          />
          <ErrorText>{errors?.email}</ErrorText>
        </ItemContainerXs2>
        <ItemContainerXs2>
          <label>Telefonnummer</label>
          <input
            value={data.telephoneNumber || ''}
            required
            onChange={handleChange('telephoneNumber')}
          />
          <ErrorText>{errors?.telephoneNumber}</ErrorText>
        </ItemContainerXs2>
        <ButtonContainer>
          <PrimaryButton onClick={handleSubmit} disabled={loading}>
            {loading ? 'Vänta, vi beräknar ditt pris' : 'Hämta mitt pris'}
          </PrimaryButton>
        </ButtonContainer>
      </FormContainer>
    </>
  );
};

export default SalesForm;

const FormContainer = styled.div`
  display: grid;

  gap: 1rem;
  padding: 1rem;
  grid-template-columns: 1fr;
  max-width: 40rem;
  margin: auto;

  @media (min-width: 32rem) {
    grid-template-columns: 1fr 1fr;
  }
`;

const PrimaryButton = styled.button`
  background-color: #e14e9b;
  font-family: DM Serif Text;
  font-size: 1.375rem;
  font-weight: 400;
  color: #fff;
  padding: 0.75rem 1.5rem;
  height: 70px;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  margin: auto;
  white-space: nowrap;
  transition: all 0.3s ease;
  box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.16),
    0px 3px 6px rgba(0, 0, 0, 0.16);

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const ErrorText = styled.div`
  font-size: 0.8125rem;
  color: #f2545b;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 35rem) {
    grid-column: 1 / span 2;
  }

  label {
    font-family: Roboto;
    font-weight: 300;
    font-size: 0.9rem;
    color: #323232;
    margin-bottom: 0.125rem;
    text-transform: uppercase;
    -webkit-appearance: none;
  }

  input {
    font-family: Roboto;
    font-weight: 300;
    font-size: 1.1rem;
    width: 100%;
    // height: 4.5rem;
    padding: 1rem;
    outline: none;
    border: 0;
    border-radius: 1.875rem;
    box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.16),
      0px 3px 6px rgba(0, 0, 0, 0.16);

    &:focus {
      border-color: #b9554d;
    }

    &:disabled {
      background-color: #f6f6f6;
    }
  }

  select {
    background-size: 12px;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
      no-repeat 95% 50%;
    appearance: none;
    font-family: Roboto;
    font-weight: 300;
    font-size: 1.1rem;
    // line-height: 2;
    width: 100%;
    padding: 1rem;
    border: 0;
    border-radius: 1.875rem;
    box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.16),
      0px 3px 6px rgba(0, 0, 0, 0.16);
    background-color: #fff;

    &:focus {
      border-color: #b9554d;
    }
  }
`;

const InfoContainer = styled.div`
  font-family: Roboto;
  font-weight: 300;
  font-size: 1.1rem;
  grid-column: 1 / span 2;
  background-color: #fff;
  padding: 1rem;
  border-radius: 1.875rem;
  box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.16),
    0px 3px 6px rgba(0, 0, 0, 0.16);
`;

const Cover = styled.div`
  display: flex;

  span {
    flex: 1;
    align-self: center;
  }
`;

const InfoContainerWithBorder = styled(InfoContainer)`
  border: 0.125rem solid #e14e9b;
`;

const InfoContainerExpandable = styled(InfoContainer)`
  font-size: 1rem;
  padding: ${({ show }) => (show ? '1rem' : '0  1rem')};
  max-height: ${({ show }) => (show ? '30rem' : 0)};
  overflow: hidden;
  transition: all 0.5s;
`;

const ItemContainerXs2 = styled(ItemContainer)`
  @media (min-width: 3rem) {
    grid-column: 1 / span 2;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 1rem;

  @media (min-width: 32rem) {
    grid-column: 1 / span 2;
    flex-direction: row;
    justify-content: flex-end;
  }
`;

const Circle = styled.div`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  border: 0.0625rem solid #707070;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.16),
    0px 3px 6px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  font-size: 1.1rem;
  color: #707070;
  transform: ${({ isUnfolded }) =>
    isUnfolded ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: all 0.5s;
`;
