import styled from 'styled-components';

const Page3 = ({ paymentSchedule }) => {
  return (
    paymentSchedule && (
      <>
        <H1>Betalningsplan</H1>
        <PaymentScheduleTable>
          <thead>
            <tr>
              <th>Nummer</th>
              <th>Betald</th>
              <th>Dag</th>
              <th>Belopp</th>
            </tr>
          </thead>
          <tbody>
            {paymentSchedule.map(payment => (
              <tr key={payment.index}>
                <td>{payment.index}</td>
                <td>{payment.collected ? 'Ja' : '-'}</td>
                <td>{payment.collectionDay}</td>
                <td>
                  {payment.amount.toLocaleString('sv-SE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{' '}
                  kr
                </td>
              </tr>
            ))}
          </tbody>
        </PaymentScheduleTable>
      </>
    )
  );
};

export default Page3;

const H1 = styled.h1`
  font-family: DM Serif Text;
  font-size: 1.8rem;
  font-weight: 300;
  color: #3c3c3c;
  line-height: 1.1;
  margin-bottom: 0.5rem;
`;

const PaymentScheduleTable = styled.table`
  width: 100%;
  text-align: left;

  th {
    font-weight: 500;
  }

  td {
    font-size: 0.75rem;
    border-top: 0.0625rem solid #e5e5e8;
    padding: 0.625rem 0;
    word-break: break-word;

    &:first-child {
      min-width: auto;
    }

    &:nth-child(2) {
      min-width: auto;
    }

    @media (min-width: 48rem) {
      font-size: 0.9375rem;
    }
  }
`;
