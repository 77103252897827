import styled from 'styled-components';

const ToggleSwitch = ({ id, checked, onChange }) => {
  // const [checked, setChecked] = useState(false);

  // const handleClick = () => {
  //   setChecked(!checked);
  // if (onChange) {
  //   console.log('Borde köra denna', !checked, onChange);

  //   onChange(!checked);
  // }
  // };

  // const handleCheckBoxChange = () => {
  //   console.log('teset');
  // };

  const handleCheckBoxChange = e => {
    console.log(e.target.checked);
    onChange(e.target.checked);
  };

  return (
    <ToggleSwithContainer checked={checked}>
      <Input
        type='checkbox'
        value={checked}
        // onChange={e => onChange(e.target.checked)}
        onChange={handleCheckBoxChange}
        checked={checked}
        name=''
        id={id}
      />
      <label htmlFor={id}>
        <span data-yes='&#10003;' data-no='' />
        <span />
      </label>
    </ToggleSwithContainer>
  );
};

export default ToggleSwitch;

const Input = styled.input`
  display: none;
`;

const ToggleSwithContainer = styled.div`
  position: relative;
  width: 3.3375rem;
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  text-align: left;

  input {
    display: none;
  }

  label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 0.71875rem;
    margin: 0;

    span {
      font-family: Roboto;
      &:first-child {
        display: block;
        width: 200%;
        margin-left: ${({ checked }) => (checked ? '0' : '-100%')};
        transition: margin 0.3s ease-in 0s;

        &:before {
          content: attr(data-yes);
          display: block;
          float: left;
          width: 50%;
          height: 1.4375rem;
          padding: 0;
          padding-left: 0.75rem;
          line-height: 1.4375rem;
          font-size: 14px;
          box-sizing: border-box;
          background-color: #e14e9b;
          color: #fff;
        }

        &:after {
          content: attr(data-no);
          display: block;
          float: left;
          width: 50%;
          height: 1.4375rem;
          padding: 0;
          padding-right: 10px;
          line-height: 1.4375rem;
          background-color: #bbb;
          color: #fff;
          text-align: right;
          box-sizing: border-box;
        }
      }

      &:last-child {
        display: block;
        position: absolute;
        top: 1px;
        bottom: 1px;
        right: ${({ checked }) => (checked ? '0.0625rem' : '1.9375rem')};
        width: 1.3125rem;
        margin: 0px;
        background-color: #fff;
        border: 0 solid #bbb;
        border-radius: 0.65625rem;
        transition: all 0.3s ease-in 0s;
      }
    }
  }
`;
