import { useEffect } from 'react';
import styled from 'styled-components';

import { useQuote } from '../../context/quoteContext';
import ErrorMessage from './components/ErrorMessage';
import ImportantInformationContainer from './components/ImportantInformationContainer';
import Price from './components/Price';
import SalesForm from './components/SalesForm';
import Header from '../../components/Header';

const OnlineSalesView = () => {
  const { error, quote } = useQuote();

  console.log(error);

  useEffect(() => {
    if (quote) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [quote]);

  return (
    <ContentContainer quote={quote ? true : false}>
      <Header />
      <SalesForm />

      {error && (
        <ErrorMessageContainer>
          <ErrorMessage />
        </ErrorMessageContainer>
      )}
      <ImportantInformationContainer />
      {quote && <Price />}
    </ContentContainer>
  );
};

export default OnlineSalesView;

const ContentContainer = styled.div`
  padding: 0;
  margin-bottom: 20rem;

  h1 {
    font-family: DM Serif Text;
    color: #3c3c3c;
    font-size: 1.6rem;
    line-height: 1.3;
    font-weight: 400;
    text-align: center;

    @media (min-width: 35rem) {
      font-size: 3.125rem;
    }
  }

  h2 {
    font-family: DM Serif Text;
    color: #3c3c3c;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.3;
  }

  h3 {
    font-family: DM Serif Text;
    color: #3c3c3c;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: 0.5rem;
  }
`;

const ErrorMessageContainer = styled.div`
  font-family: Roboto;
  font-weight: 300;
  font-size: 1.1rem;
  max-width: 40rem;
  margin: auto;
  padding: 1rem 0;
`;
