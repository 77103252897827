import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useQuote } from '../../../context/quoteContext';

const Price = () => {
  const { quote } = useQuote();
  const history = useHistory();

  const handlePurchaseClick = () => history.push(`/pay/trustly/${quote.id}`);

  return (
    <PriceContainer quote={quote ? true : false}>
      <NameContainer>
        {quote.answers.firstName} {quote.answers.lastName} Hemförsäkring
      </NameContainer>
      <PricePerMonth>
        <span>
          {quote.premium.monthly.toLocaleString('sv-SE', {
            maximumFractionDigits: 0,
          })}
        </span>
        <span>kr/mån</span>
      </PricePerMonth>
      <hr />
      <ButtonContainer>
        <PurchaseButton onClick={handlePurchaseClick}>
          Genomför betalning
        </PurchaseButton>
      </ButtonContainer>
    </PriceContainer>
  );
};

export default Price;

const PriceContainer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  width: 100%;
  background-color: #e24e9b;
  border-radius: 2.5rem 2.5rem 0 0;
  color: #fff;
  padding: 1rem 2rem;
  margin-bottom: 0;

  hr {
    height: 0.1875rem;
    border: 0;
    background-color: #fff;
    margin: 0 12.5rem;
  }
`;

const NameContainer = styled.span`
  font-family: DM Serif Text;
  font-size: 1.4rem;
  font-weight: 400;
  color: #fff;
  margin: auto;

  @media (min-width: 32rem) {
    font-size: 1.8rem;
  }

  @media (min-width: 48rem) {
    font-size: 2.5rem;
  }
`;

const PricePerMonth = styled.div`
  display: flex;
  justify-items: flex-end;
  font-family: DM Serif Text;
  font-size: 3rem;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  margin: auto;
  margin-bottom: 1rem;

  span {
    align-self: flex-end;

    &:last-child {
      font-family: Roboto;
      font-size: 1.5rem;
      font-weight: 300;
      margin-left: 0.5rem;
    }
  }
`;

const ButtonContainer = styled.div`
  margin: 1rem auto;
`;

const PurchaseButton = styled.button`
  border: 0;
  background-color: #eddcdc;
  font-family: DM Serif Text;
  font-size: 1.4rem;
  font-weight: 400;
  color: #3c3c3c;
  padding: 0.75rem 3.25rem;
  border-radius: 2.5rem;
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.9;
  }
`;
