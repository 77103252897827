import styled from 'styled-components';
import { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { reattachService } from '@eir/services';

import CroisetteLogo from '../../images/Croisette_logo_Pink.png';

const AttachView = () => {
  const match = useRouteMatch('/reattach/:paymentProvider/:ledgerId');
  const [error, setError] = useState(true);

  const reattchLedger = async () => {
    try {
      const data = await reattachService(
        'CroisetteAPI',
        match.params.paymentProvider,
        match.params.ledgerId
      );
      window.location.replace(data.redirectUrl);
    } catch (err) {
      setError(true);
      console.log(err);
    }
  };

  reattchLedger();

  return error ? (
    <PolicyLetterContainer>
      <header>
        <Logo src={CroisetteLogo} alt='Croisette tillägg' />
        <h1>Något gick fel</h1>
      </header>
      <main>
        Det gick inte att genomföra betalningen. Vänligen kontakta oss så
        hjälper vi dig. Du når oss enklast på{' '}
        <a href='mailto:infocroisette@eirforsakring.se'>
          infocroisette@eirforsakring.se
        </a>
        .
      </main>
    </PolicyLetterContainer>
  ) : (
    <PolicyLetterContainer>
      <header>
        <Logo src={CroisetteLogo} alt='Croisette tillägg' />
        <h1>Vänligen vänta..</h1>
      </header>
    </PolicyLetterContainer>
  );
};

export default AttachView;

const PolicyLetterContainer = styled.div`
  width: 100%;
  max-width: 56.25rem;
  margin: 1.875rem auto;
  font-family: 'Poppins';
  color: #7b7d8c;
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  header {
    display: flex;
    flex-direction: column;
    margin: 1.875rem 0;
    padding: 0 1.5625rem;
  }

  main {
    padding: 0.625rem;

    @media (min-width: 48rem) {
      padding: 1.875rem;
    }
  }
`;

const Logo = styled.img`
  width: 100%;
  height: auto;
  max-width: 15rem;
  margin: 0.625rem 0 1.875rem;
  border: 0;

  @media (min-width: 48rem) {
    margin: 2.5rem 0;
  }
`;
